import {makeContextFeature, makeItemFeature, makeListFeature, tracking} from "./shared";
import {send, trackingTypes} from "./tracking_api";
import {collectModuleTrackingData} from "./view_tracking";

function isModuleEntryPage(contextElem) {
    return '1' === contextElem.dataset.navLevel;
}

function isExpanded(el) {
    const parent = window.o_util.dom.getParentByClassName(el, 'pl_table-view__item--fully-clickable');
    return !parent.dataset.nav_menuExpanderCollapsable || parent.style.display !== 'none';
}

function buildFeatures(action, overlay) {
    if (action === "close") {
        return [];
    }

    let contextElem = overlay.closest(tracking.context);
    let ctxFeature = makeContextFeature(contextElem);
    let features = [ctxFeature];

    if (isModuleEntryPage(contextElem)) {
        return collectModuleTrackingData(contextElem);
    }

    let listElems = contextElem.querySelectorAll('.nav_expanded-chips__body .nav_expanded-chips__list');

    Array.from(listElems)
        .map((elem) => {
            let lf = makeListFeature(elem);
            let listPosition = parseInt(elem.dataset.position);
            let listItems = elem.querySelectorAll(tracking.item);

            features.push(lf);

            Array.from(listItems)
                .filter(isExpanded)
                .map((liElem) => features.push(makeItemFeature(liElem, listPosition)));
        });

    return features;
}

/**
 *
 *
 */
export function trackOpenClose(action, overlay) {
    send(trackingTypes.action, {}, { name: action, features: buildFeatures(action, overlay) });
}


