export const CSS_CLASS = {
    block: 'nav_slidein_js'
};

export function show(element) {
    element.classList.remove(CSS_CLASS.block + '--hidden');
    setTimeout(() => {
        element.classList.remove(CSS_CLASS.block + '--slidden-down');
    }, 10);
}

export function hide(element) {
    element.classList.add(CSS_CLASS.block + '--slidden-down');
    element.classList.add(CSS_CLASS.block + '--hidden');
}