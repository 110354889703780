export class VisibilityTracker {
    constructor(trackFn) {
        this.alreadyTracked = {};
        this.observer = new IntersectionObserver((entries, o)  => {
            entries.forEach((entry) => {
                if (!this.alreadyTracked[entry.target] && entry.isIntersecting) {
                    trackFn();
                    this.alreadyTracked[entry.target] = true;
                }
            });
        }, {
            threshold: 0.0
        });
    }

    observe(target) {
        this.observer.observe(target);
    }
}
