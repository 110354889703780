import {trackOpenClose} from "./tracking/expander_tracking";
import * as slideIn from "./dom/slide_in";
import {tracking} from "./tracking/shared";

const disableScrollClass = 'nav_promirnaty__body--scrolling-disabled';

function showOverlay(overlay, evt) {
    evt.preventDefault();
    slideIn.show(overlay);
    document.body.classList.add(disableScrollClass);

    trackOpenClose('open', overlay);
}

function hideOverlay(overlay, evt) {
    evt.preventDefault();
    document.body.classList.remove(disableScrollClass);
    slideIn.hide(overlay);

    trackOpenClose('close', overlay);
}

export function initExpander(parentElem) {
    let contextElems = parentElem.querySelectorAll(tracking.context);

    for (let i = 0; i < contextElems.length; i++) {
        const contextElem = contextElems[i];
        let breakpoints = []
        try {
            breakpoints = JSON.parse(contextElem.dataset.breakpoints);
        } catch (e) {}

        if (breakpoints.includes(o_global.breakpoint.getCurrentBreakpoint())) {
            const toggleButton = contextElem.querySelector(".nav_chips-expander__button");
            const closeButton = contextElem.querySelector(".nav_expanded-chips__close-button");
            const overlay = contextElem.querySelector(".nav_slidein_js");

            if (toggleButton && closeButton && overlay) {
                toggleButton.addEventListener('click', (e) => showOverlay(overlay, e))
                closeButton.addEventListener('click', (e) => {
                    hideOverlay(overlay, e)
                    toggleButton.scrollIntoView({ block: "center" });
                }
                )
            }
        }
    }
}

