import {logger} from "@otto-ec/global-resources/debug";

const log = logger("ftnav.promirnaty.tracking_api");

export const trackingTypes = {
    tsSend: "tracking.bct.sendEventToTrackingServer",
    moveAction: "tracking.bct.submitMoveAction",
    action: "tracking.bct.submitAction",
    viewTracking: 'tracking.bct.addFeaturesToPageImpression',
    addActionToEvent: 'tracking.bct.addActionToEvent',
    addToEvent: 'tracking.bct.addToEvent',
};

/**
 *
 *
 */
export function send(type, ...args) {
    if (o_global && o_global.eventQBus && typeof o_global.eventQBus.emit === 'function') {
        log.debug("promirnaty.trackingDebugLog", type, ...args);
        o_global.eventQBus.emit(type, ...args);
    }
}
