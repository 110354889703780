import {makeContextFeature, makeItemFeature, makeListFeature, tracking} from "./shared";
import {send, trackingTypes} from "./tracking_api";

/**
 *
 *
 *
 */
export function collectModuleTrackingData(contextElem, sliderType) {
    let featureContext = makeContextFeature(contextElem, {sliderType: sliderType});
    let listElem = contextElem.querySelector(tracking.list);
    if (!listElem) {
        return [];
    }
    let itemElems = listElem.querySelectorAll(tracking.item);

    /*                                               */
    let listFeatures = Array.from(itemElems).map(el => makeListFeature(el, {sliderType: sliderType}));
    return [featureContext, ...listFeatures];
}

function collectTrackingData(contextElem, sliderType) {
    let contextFeature = makeContextFeature(contextElem, {sliderType: sliderType});
    let listElems = contextElem.querySelectorAll(tracking.list);

    if (listElems.length < 1) {
        return [];
    }

    let listElem = listElems[0];
    let listFeature = makeListFeature(listElem);
    let listItems = listElem.querySelectorAll(tracking.item);

    let itemFeatures = Array.from(listItems).map((el) => makeItemFeature(el, 0));
    return [contextFeature, listFeature, ...itemFeatures];
}

/**
 *
 *
 *
 */
function track(features, sliderType, opts) {
    switch (sliderType) {
        case "empty-suggest-slider":
            send(trackingTypes.addActionToEvent, {
                name: "open",
                features: features
            }, opts.eventMergeId);
            break;
        default:
            send(trackingTypes.viewTracking, features);
            break;
    }
}

export function trackPageView(parentElem, sliderType, opts) {
    let contextElems = parentElem.querySelectorAll(tracking.context);

    for (let i = 0; i < contextElems.length; i++) {
        let collectedFeatures = [];
        const contextElem = contextElems[i];
        let breakpoints = [];
        try {
            breakpoints = JSON.parse(contextElem.dataset.breakpoints);
        } catch (e) {
        }

        if (breakpoints.includes(o_global.breakpoint.getCurrentBreakpoint())) {
            switch (contextElem.dataset.pageType) {
                case 'module':
                    collectedFeatures = collectModuleTrackingData(contextElem, sliderType);
                    break;
                default:
                    collectedFeatures = collectTrackingData(contextElem, sliderType);
                    break;
            }
            if (collectedFeatures.length > 0) {
                track(collectedFeatures, sliderType, opts);
            }
        }
    }
}
